import React from "react"
import {graphql} from "gatsby"
import Layout from "../components/Layout"
import { GatsbyImage } from "gatsby-plugin-image"
import {Helmet} from "react-helmet";
import {SEO} from "../components/Seo";
import Newsletter from "../components/Newsletter";

const Article = ({data}) => {
    const { html } = data.markdownRemark
    const { title, tag, image, link, claim, features, pages, language, price } = data.markdownRemark.frontmatter
    return(
      <Layout key={data.link}>
          <Helmet>
              <title>{title} | Business Intelligence Warehouse | SAP-Wissen wie ein Espresso (Bücher & E-Books)</title>
          </Helmet>
          <section className="bg-blue-200 text-gray-800 pt-16 pb-10 text-left">
              <div className="mx-auto max-w-3xl px-4 sm:px-6 xl:max-w-5xl xl:px-0">
                  <h1 className="font-semibold md:text-4xl text-2xl pb-4">{title}</h1>
                  <p className="lg:text-2xl sm:text-lg text-sm">{tag}</p>
              </div>
          </section>
          <div className="mx-auto max-w-3xl px-4 sm:px-6 xl:max-w-5xl xl:px-0 flex flex-row flex-wrap py-10">
              <div className="w-full md:w-content md:pr-16 lg:pr-24">
                  <div className="w-full mt-9 article">
                      <div className="prose lg:prose-xl" dangerouslySetInnerHTML={{ __html: html }}></div>
                      <div className="relative mt-8 p-4 md:p-8 rounded-xl shadow-xl border-2 border-solid border-gray-400 flex flex-row flex-wrap">
                          <span className="absolute top-0 right-0 py-1 px-3 rounded-lg bg-blue-500 text-white text-sm font-semibold shadow -mt-4 -mr-3">{price} €</span>
                          <div className="w-full sm:w-1/3 pr-4 sm:pr-8 mb-6 sm:mb-0 overflow-hidden">
                              <GatsbyImage image={image.childImageSharp.gatsbyImageData} alt={title} className="border-0 w-full h-auto block overflow-hidden"/>
                          </div>
                          <div className="w-full sm:w-2/3">
                              <h3 className="text-lg md:text-xl font-semibold text-gray-800 leading-tight">{title}</h3>
                              <ul className="flex flex-row items-center flex-wrap text-gray-700 font-semibold text-sm mt-2">
                                  <li className="mr-4">Seiten: {pages}</li>
                                  <li>Sprache: {language}</li>
                              </ul>
                              <p className="text-gray-700 mt-2">{claim}</p>
                              <ul className="list-disc pl-4 mt-2">
                                  {features.map(name => (
                                      <li className="text-gray-700">
                                          {name}
                                      </li>
                                  ))}
                              </ul>
                              <a title="Jetzt im Espresso Tutorials Shop kaufen" className="block mt-4 rounded-lg bg-blue-500 text-center hover:bg-orange-500 transition-colors duration-200 ease-in-out text-white font-semibold text-sm py-2 px-4" target="_blank" href={link} rel="noopener noreferrer">
                                  Jetzt kaufen
                              </a>
                          </div>
                      </div>
                      <p className="pt-8 text-xs">Themen: {tag}</p>
                      <Newsletter></Newsletter>
                  </div>
              </div>
              <div className="hidden md:block w-sidebar">
                  <article className="p-4 md:p-8 mb-12 relative rounded-xl shadow-2xl group">
                      <span className="absolute top-0 right-0 py-1 px-3 rounded-lg bg-blue-500 text-white text-sm font-semibold shadow -mt-4 -mr-3">{price} €</span>
                      <a href={link} target="_blank" rel="noopener nofollow noreferrer" title={title}>
                          <GatsbyImage image={image.childImageSharp.gatsbyImageData} alt={title} className="w-full h-auto block overflow-hidden"/>
                          <h3 className="font-semibold group-hover:text-orange-500 text-blue-500 transition-colors duration-200 ease-in-out mb-2 capitalize text-lg mt-4">{title}</h3>
                          <ul className="flex flex-row items-center flex-wrap text-gray-700 font-semibold text-sm mt-2">
                              <li className="mr-4">Seiten: {pages}</li>
                              <li>Sprache: {language}</li>
                          </ul>
                          <p className="text-gray-700 mt-2">{claim}</p>
                          <span title="Jetzt im Espresso Tutorials Shop kaufen" className="block mt-4 rounded-lg bg-blue-500 text-center group-hover:bg-orange-500 transition-colors duration-200 ease-in-out text-white font-semibold text-sm py-2 px-4">
                                Jetzt kaufen
                            </span>
                      </a>
                  </article>

              </div>
          </div>
        </Layout>
    )
}

export default Article

export const Head = () => (
    <SEO/>
)

export const query = graphql`
  query ArticleQuery($slug: String) {
    markdownRemark(frontmatter: {slug: {eq: $slug}}) {
      html
      frontmatter {
        title
            tag
            excerpt
            link
            claim
            pages
            rating
            features
            language
            price
            image {
                childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                }
            }
      }
    }
  }
`
